<template>
  <div v-if="currentQuestion">
    <p>{{ displayText }}</p>
    <button @click="requestHint" v-if="selectedMode === 'event-description' || currentQuestion.type === 'specific-answer'">Получить подсказку</button>
    <p v-if="hint">Подсказка: {{ hint }}</p>
    <button @click="requestHint" v-if="hint">Получить другую подсказку</button>
  </div>
</template>

<script>
import { generateQuestionWording } from '@/methods/questionHandling.js';

export default {
  props: ['currentQuestion', 'selectedMode', 'selectedSubject'],
  data() {
    return {
      uniqueQuestion: null,
      hint: null,
      isLoading: false
    };
  },
  computed: {
    displayText() {
      if (this.isLoading) {
        return 'Загрузка...';
      }

      if (this.selectedMode === 'event-description' && this.currentQuestion.type === 'specific-answer') {
        return this.currentQuestion.event;
      }
      
      if (this.selectedMode === 'event-description' || this.currentQuestion.type === 'specific-answer') {
        return this.uniqueQuestion || this.currentQuestion.event;
      }
      
      return generateQuestionWording(this.currentQuestion.event, this.currentQuestion.type);
    },
    showImage() {
      return this.currentQuestion.type === 'specific-answer' && this.currentQuestion.imageUrl;
    }
  },
  watch: {
    currentQuestion: {
      immediate: true,
      handler(newQuestion) {
        if (this.selectedMode === 'event-description' && newQuestion && newQuestion.explanation && newQuestion.type !== 'specific-answer') {
          this.generateUniqueQuestion(newQuestion.event, newQuestion.explanation);
        } else {
          this.uniqueQuestion = null;
          this.isLoading = false;
        }
      }
    }
  },
  methods: {
    async generateUniqueQuestion(event, explanation) {
      this.isLoading = true;
      try {
        const response = await fetch('/api/generate-question', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ event, explanation }),
        });
        const data = await response.json();
        this.uniqueQuestion = data.question;
        console.log('Unique question generated:', this.uniqueQuestion);
      } catch (error) {
        console.error('Error generating unique question:', error);
        this.uniqueQuestion = `Определите событие по следующему описанию: ${explanation}`;
      } finally {
        this.isLoading = false;
      }
    },
    
    async requestHint() {
      try {
        const response = await fetch('/api/generate-hint', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            event: this.currentQuestion.event,
            explanation: this.currentQuestion.explanation,
            correctAnswer: this.currentQuestion.correctAnswer,
            questionType: this.currentQuestion.type,
            generated_question: this.uniqueQuestion || this.questionText
          }),
        });
        const data = await response.json();
        this.hint = data.hint;
      } catch (error) {
        console.error('Error generating hint:', error);
        this.hint = 'Не удалось сгенерировать подсказку.';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.question-display {
  text-align: center;
  margin-bottom: 1.5rem;
}

.date-part-label {
  font-style: italic;
  color: $color-text-secondary;
}

.explanation {
  font-style: italic;
  color: $color-text-secondary;
  margin-top: 1rem;
  font-size: 0.9em;
}

.question-image {
  max-width: 100%;
  height: auto;
  margin: 1rem 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>


<template>
  <div class="subject-selector">
    <h2>Выберите предмет</h2>
    <ul class="subject-list">
      <li @click="onSubjectSelect('Случайная тема')" class="subject-item random-subject">
        Случайная тема
      </li>
      <li v-for="subject in subjects" :key="subject" @click="onSubjectSelect(subject)" class="subject-item">
        {{ subject }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['subjects', 'selectSubject'],
  methods: {
    onSubjectSelect(subject) {
      if (subject === "Случайная тема") {
        const filteredSubjects = this.subjects.filter(s => s !== "Случайная тема");
        const randomSubject = filteredSubjects[Math.floor(Math.random() * filteredSubjects.length)];
        this.selectSubject(randomSubject);
      } else {
        this.selectSubject(subject);
      }
    }
  }
};
</script>

import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL || 'http://localhost:5000/api';

console.log('API_URL:', API_URL);

export const fetchQuestions = async () => {
  try {
    console.log('Fetching questions from:', `${API_URL}/questions`);
    const response = await axios.get(`${API_URL}/questions`);
    console.log('Response received:', response);
    if (Array.isArray(response.data)) {
      console.log('Received questions:', response.data.length);
      return response.data;
    } else {
      console.error('Fetched data is not an array:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching questions:', error.message);
    console.error('Error details:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }
    return [];
  }
};

export const checkAnswer = async (questionId, userAnswer) => {
  try {
    const response = await axios.post(`${API_URL}/check-answer`, { questionId, userAnswer });
    return response.data;
  } catch (error) {
    console.error('Error checking answer:', error);
    throw error;
  }
};

export const updateStatistics = async (userId, statistics) => {
  try {
    const response = await axios.post(`${API_URL}/statistics`, { userId, statistics });
    return response.data;
  } catch (error) {
    console.error('Error updating statistics:', error);
    throw error;
  }
};

export const fetchSequenceQuestions = async (subject) => {
  try {
    const response = await axios.get(`${API_URL}/sequence-questions`, {
      params: { subject }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching sequence questions:', error);
    throw error;
  }
};

export const checkSequence = async (sequence) => {
  try {
    const response = await axios.post(`${API_URL}/check-sequence`, { sequence });
    return response.data;
  } catch (error) {
    console.error('Error checking sequence:', error);
    throw error;
  }
};
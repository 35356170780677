<template>
  <div>
    <input 
      type="number" 
      v-model="localDecade" 
      placeholder="Введите десятилетие (например, 1910 для 1910-х)"
      @input="updateUserAnswer"
    />
    <span>{{ era }}</span>
  </div>
</template>

<script>
export default {
  props: ['userAnswer', 'era'],
  data() {
    return {
      localDecade: ''
    }
  },
  methods: {
    updateUserAnswer() {
      if (this.localDecade) {
        this.$emit('update:userAnswer', this.localDecade);
        console.log('Emitting new userAnswer:', this.localDecade);
      }
    },
    updateLocalValue() {
      this.localDecade = this.userAnswer || '';
    }
  },
  watch: {
    userAnswer: {
      immediate: true,
      handler() {
        this.updateLocalValue();
      }
    }
  },
  mounted() {
    this.updateLocalValue();
  }
}
</script>

<template>
  <div id="app">
    <h1>Вопросы по истории</h1>
    
    <div class="mode-selector">
      <QuestionModeSelector
        v-if="!questionsLoaded"
        :selectMode="selectMode"
        :questionsLoaded="questionsLoaded"
      />
    </div>
    
    <div class="subject-selector">
      <SubjectSelector
        v-if="showSubjectSelector"
        :subjects="subjects"
        :selectSubject="selectSubject"
      />
    </div>

    <div v-if="questionsLoaded && (!showSubjectSelector || selectedSubject)" class="question-container">
      <h2 v-if="selectedSubject">{{ selectedSubject }}</h2>
      
      <QuestionDisplayComponent 
        v-if="selectedMode !== 'sequence' && selectedMode !== 'term-to-definition' && selectedMode !== 'definition-to-term' && selectedMode !== 'image-quiz' && selectedMode !== 'multiplayer'"
        ref="questionDisplay"
        :currentQuestion="currentQuestion" 
        :datePartLabel="datePartLabel"
        :selectedMode="selectedMode"
        :selectedSubject="selectedSubject"
      />

      <DefinitionQuestionComponent
        v-if="selectedMode === 'term-to-definition' || selectedMode === 'definition-to-term'"
        :currentQuestion="currentQuestion"
        :selectedMode="selectedMode"
        @answer-submitted="checkAnswer"
      />

      <SequenceQuestionComponent 
        v-if="selectedMode === 'sequence' && sequenceEvents.length > 0"
        :events="sequenceEvents"
        @answer-checked="handleSequenceAnswer"
        @next-sequence="loadNextSequence"
      />

      <p v-if="selectedMode === 'sequence' && sequenceEvents.length === 0">Нет доступных событий для упорядочивания.</p>

      <EventDescriptionInput
        v-if="selectedMode === 'event-description' || (currentQuestion && currentQuestion.type === 'specific-answer')"
        :description="currentQuestion.explanation"
        :correctEventName="currentQuestion.correctAnswer"
        :userAnswer="userAnswer"
        :questionType="currentQuestion.type"
        @update:userAnswer="userAnswer = $event"
        @submit-answer="handleEventDescriptionAnswer"
      />

      <ImageQuizComponent
        v-if="selectedMode === 'image-quiz' && currentImageQuestion"
        :currentQuestion="currentImageQuestion"
        @answer-checked="handleImageQuizAnswer"
        ref="imageQuizComponent"
      />

      <MultiplayerGame v-if="selectedMode === 'multiplayer'" />

      <div v-if="selectedMode !== 'sequence' && selectedMode !== 'event-description' && selectedMode !== 'term-to-definition' && selectedMode !== 'definition-to-term' && selectedMode !== 'image-quiz' && selectedMode !== 'multiplayer'" class="input-group">
        <DateInputComponent
          v-if="currentQuestion && (currentQuestion.type === 'year' || currentQuestion.type === 'exact-date' || !currentQuestion.type)"
          :datePart="datePart"
          v-model:userAnswer="userAnswer"
          :era="currentQuestion.era"
          @keydown.enter="checkAnswer"
        />
        
        <DateInputComponent
          v-if="currentQuestion && currentQuestion.type === 'century'"
          datePart="century"
          v-model:userAnswer="userAnswer"
          :era="currentQuestion.era"
          @keydown.enter="checkAnswer"
        />
        
        <RangeInputComponent
          v-if="currentQuestion && currentQuestion.type === 'range'"
          v-model:userAnswer="userAnswer"
          :era="currentQuestion.era"
          @keydown.enter="checkAnswer"
        />
        
        <DecadeInputComponent
          v-if="currentQuestion && currentQuestion.type === 'decade'"
          v-model:userAnswer="userAnswer"
          :era="currentQuestion.era"
          @keydown.enter="checkAnswer"
        />
        
        <DecadeRangeInputComponent
          v-if="currentQuestion && currentQuestion.type === 'decade-range'"
          v-model:userAnswer="userAnswer"
          :era="currentQuestion.era"
          @keydown.enter="checkAnswer"
        />
        
        <MonthRangeInputComponent
          v-if="currentQuestion && currentQuestion.type === 'month-range'"
          v-model:userAnswer="userAnswer"
          :era="currentQuestion.era"
          @keydown.enter="checkAnswer"
        />
        
        <MonthYearRangeInputComponent
          v-if="currentQuestion && currentQuestion.type === 'month-year-range'"
          v-model:userAnswer="userAnswer"
          :era="currentQuestion.era"
          @keydown.enter="checkAnswer"
        />
        
        <DecadePeriodInputComponent
          v-if="currentQuestion && currentQuestion.type === 'decade-period'"
          v-model:userAnswer="userAnswer"
          :era="currentQuestion.era"
          @keydown.enter="checkAnswer"
        />
        
        <DayRangeInputComponent
          v-if="currentQuestion && currentQuestion.type === 'day-range'"
          v-model:userAnswer="userAnswer"
          :era="currentQuestion.era"
          @keydown.enter="checkAnswer"
        />
        
        <MonthYearInputComponent
          v-if="currentQuestion && currentQuestion.type === 'month-year'"
          v-model:userAnswer="userAnswer"
          :era="currentQuestion.era"
          @keydown.enter="checkAnswer"
        />
      </div>
      
      <button @click="checkAnswer()" v-if="selectedMode !== 'event-description' && selectedMode !== 'sequence' && selectedMode !== 'term-to-definition' && selectedMode !== 'definition-to-term' && selectedMode !== 'image-quiz' && selectedMode !== 'multiplayer'">Проверить Ответ</button>
      
      <ResultDisplayComponent 
        :isCorrect="isCorrect"
        :correctAnswer="currentQuestion ? currentQuestion.correctAnswer : ''"
        :show="showSuccessOverlay"
        :selectedMode="selectedMode"
        :explanation="answerExplanation"
        :imageUrl="currentQuestion ? currentQuestion.imageUrl : ''"
        :showImage="isAnswerChecked"
        @hide-overlay="hideSuccessOverlay"
      />
      
      <ExplanationComponent
        v-if="selectedMode !== 'image-quiz'"
        ref="explanationComponent"
        :explanation="currentQuestion ? currentQuestion.explanation : ''"
        :isVisible="isExplanationVisible"
        :questionType="currentQuestion ? currentQuestion.type : ''"
        :selectedMode="selectedMode"
        @update:isVisible="isExplanationVisible = $event"
      />

      <SourcesComponent 
        :sources="currentQuestion ? currentQuestion.sources : []"
      />

      
      <button @click="nextQuestion" v-if="selectedMode !== 'sequence'">Следующий Вопрос</button>
    </div>

    <p v-if="questionsLoaded && !currentQuestion && (!showSubjectSelector || selectedSubject) && selectedMode !== 'sequence'">Загрузка вопросов...</p>
    
    <StatisticsComponent :statistics="statistics" :userId="userId" />
  </div>
</template>

<script>
import QuestionModeSelector from './components/QuestionModeSelector.vue';
import QuestionDisplayComponent from './components/QuestionDisplayComponent.vue';
import DateInputComponent from './components/DateInputComponent.vue';
import ResultDisplayComponent from './components/ResultDisplayComponent.vue';
import SubjectSelector from './components/SubjectSelector.vue';
import { shuffleQuestions, generateQuestionWording } from './methods/questionHandling.js';
import { determineDatePart } from './methods/dateHandling.js';

import RangeInputComponent from './components/RangeInputComponent.vue';
import DecadeInputComponent from './components/DecadeInputComponent.vue';
import DecadeRangeInputComponent from './components/DecadeRangeInputComponent.vue';
import MonthRangeInputComponent from './components/MonthRangeInputComponent.vue';
import MonthYearRangeInputComponent from './components/MonthYearRangeInputComponent.vue';
import DecadePeriodInputComponent from './components/DecadePeriodInputComponent.vue';
import ExplanationComponent from './components/ExplanationComponent.vue';
import SourcesComponent from './components/SourcesComponent.vue';
import DayRangeInputComponent from './components/DayRangeInputComponent.vue';
import MonthYearInputComponent from './components/MonthYearInputComponent.vue';
import { ref, watch, onMounted } from 'vue';
import StatisticsComponent from './components/StatisticsComponent.vue';
import EventDescriptionInput from './components/EventDescriptionInput.vue';

import { fetchQuestions, updateStatistics as apiUpdateStatistics, fetchSequenceQuestions } from './api';
import SequenceQuestionComponent from './components/SequenceQuestionComponent.vue';
import DefinitionQuestionComponent from './components/DefinitionQuestionComponent.vue';
import ImageQuizComponent from './components/ImageQuizComponent.vue';
import MultiplayerGame from './components/MultiplayerGame.vue';

export default {
  components: {
    QuestionModeSelector,
    SubjectSelector,  // Register the SubjectSelector component here
    QuestionDisplayComponent,
    DateInputComponent,
    ResultDisplayComponent,
    RangeInputComponent,
    DecadeInputComponent,
    DecadeRangeInputComponent,
    MonthRangeInputComponent,
    MonthYearRangeInputComponent,
    DecadePeriodInputComponent,
    ExplanationComponent,
    SourcesComponent,
    DayRangeInputComponent,
    MonthYearInputComponent,
    StatisticsComponent,
    EventDescriptionInput,
    SequenceQuestionComponent,
    DefinitionQuestionComponent,
    ImageQuizComponent,
    MultiplayerGame,
  },
  data() {
    return {
      questions: [],
      currentIndex: 0,
      userAnswer: '',
      isCorrect: null,
      datePart: '',
      datePartLabel: '',
      selectedMode: null,
      questionsLoaded: false,
      selectedSubject: null,  // New property for the selected subject
      // Remove this line: subjects: [],
      isExplanationVisible: false,
      showSuccessOverlay: false,
      answerExplanation: '',
      isAnswerChecked: false,
      sequenceEvents: [],
      currentImageQuestion: null,
      subjectsWithDefinitions: [], // Новое поле для тем с вопросами типа 'definition'
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentIndex] || null;
    },
    showCenturySelector() {
      return (this.selectedMode === 'century' || this.selectedMode === 'decade') && !this.selectedCentury;
    },
    showDecadeSelector() {
      return this.selectedMode === 'decade' && this.selectedCentury && !this.selectedDecade;
    },
    showSubjectSelector() {
      return (this.selectedMode === 'subject' || this.selectedMode === 'event-description' || this.selectedMode === 'sequence' || this.selectedMode === 'term-to-definition' || this.selectedMode === 'definition-to-term' || this.selectedMode === 'image-quiz' || this.selectedMode === 'multiplayer') && !this.selectedSubject;
    }
  },
  methods: {
    async loadQuestions() {
      console.log(`Loading questions for mode: ${this.selectedMode}`);
      try {
        const questions = await fetchQuestions();
        if (Array.isArray(questions)) {
          if (this.selectedMode === 'event-description') {
            this.questions = questions.filter(q => q.explanation && q.explanation.trim() !== '');
          } else if (this.selectedMode === 'subject') {
            this.questions = questions.filter(q => q.type !== 'specific-answer');
          } else if (this.selectedMode === 'term-to-definition' || this.selectedMode === 'definition-to-term') {
            this.questions = questions.filter(q => q.type === 'definition');
          } else {
            this.questions = questions;
          }
          console.log(`Loaded ${this.questions.length} questions for ${this.selectedMode} mode`);
          this.shuffleQuestions(this.questions);
          this.populateSubjects();
          this.questionsLoaded = true;
          console.log('Questions loaded and shuffled');
          
          // Log details about the first few questions
          console.log('Sample of loaded questions:');
          this.questions.slice(0, 5).forEach((q, index) => {
            console.log(`Question ${index + 1}:`, {
              event: q.event,
              type: q.type,
              subject: q.subject,
              hasExplanation: !!q.explanation
            });
          });
          
          // Set the first question
          if (this.questions.length > 0) {
            this.currentIndex = 0;
            console.log('Initial question set:', this.questions[0]);
            this.determineDatePart();
          } else {
            console.log('No questions loaded for the current mode');
          }
        } else {
          console.error('Fetched questions is not an array:', questions);
        }
      } catch (error) {
        console.error('Error loading questions:', error);
      }
    },
    populateSubjects() {
      if (!Array.isArray(this.questions)) {
        console.error('this.questions is not an array:', this.questions);
        return;
      }
      
      const subjectSet = new Set();
      const subjectWithDefinitionsSet = new Set();
      
      this.questions.forEach(question => {
        if (question && question.subject) {
          subjectSet.add(question.subject);
          if (question.type === 'definition') {
            subjectWithDefinitionsSet.add(question.subject);
          }
        }
      });

      this.subjects = Array.from(subjectSet).sort();
      this.subjectsWithDefinitions = Array.from(subjectWithDefinitionsSet).sort();
      console.log('Populated subjects:', this.subjects);
      console.log('Populated subjects with definitions:', this.subjectsWithDefinitions);
    },
    async selectMode(mode) {
      console.log(`Selecting mode: ${mode}`);
      this.selectedMode = mode;
      this.selectedSubject = null;
      
      if (!this.questions || this.questions.length === 0) {
        console.log('Questions not loaded, loading now...');
        await this.loadQuestions();
      }

      this.showSubjectSelector = ['sequence', 'subject', 'term-to-definition', 'definition-to-term', 'image-quiz', 'multiplayer'].includes(mode);

      if (this.showSubjectSelector) {
        this.populateSubjects();
        if (mode === 'image-quiz') {
          // Используем только темы с вопросами типа 'definition' для режима 'image-quiz'
          this.subjects = this.subjectsWithDefinitions;
        }
      } else if (mode === 'random') {
        await this.selectRandomSubject();
      } else if (mode === 'event-description') {
        this.loadQuestions();
      }
    },
    async selectRandomSubject() {
      if (!this.subjects || this.subjects.length === 0) {
        await this.populateSubjects();
      }
      const randomSubject = this.subjects[Math.floor(Math.random() * this.subjects.length)];
      console.log('Randomly selected subject:', randomSubject);
      await this.selectSubject(randomSubject);
    },
    async selectSubject(subject) {
      console.log('Selecting subject:', subject);
      this.selectedSubject = subject === "Случайная тема" ? "Случайная тема" : subject;
      this.showSubjectSelector = false;
      if (this.selectedMode === 'image-quiz') {
        this.prepareImageQuizQuestions();
      } else if (this.selectedMode === 'sequence') {
        await this.loadSequenceQuestions();
      } else {
        await this.loadQuestions();
        this.questions = this.questions.filter(q => q.subject === subject && q.type !== 'specific-answer');

        console.log(`Filtered ${this.questions.length} questions for subject: ${subject}`);
        this.shuffleQuestions(this.questions);
        this.currentIndex = 0;
        this.determineDatePart();
        this.questionsLoaded = true;
        if (this.questions.length > 0) {
          console.log('Current question set:', this.questions[0]);
        } else {
          console.error('No questions found for the selected subject');
        }
      }
    },
    prepareImageQuizQuestions() {
      const definitionQuestions = this.questions.filter(q => q.subject === this.selectedSubject && q.type === 'definition');
      if (definitionQuestions.length < 4) {
        console.error('Not enough definition questions for this subject');
        return;
      }
      this.shuffleQuestions(definitionQuestions);
      const mainQuestion = definitionQuestions[0];
      const otherQuestions = definitionQuestions.slice(1, 4);
      
      this.currentImageQuestion = {
        question: `Какой термин соответствует этой картинке?`,
        imageUrl: mainQuestion.imageUrl, // Предполагается, что у вопроса есть поле imageUrl
        options: [
          mainQuestion.term,
          ...otherQuestions.map(q => q.term)
        ].sort(() => Math.random() - 0.5),
        correctAnswer: mainQuestion.term,
        definition: mainQuestion.definition,
        subject: mainQuestion.subject,
        explanation: mainQuestion.explanation
      };
      
      this.questionsLoaded = true;
    },
    async checkAnswer(providedAnswer = null) {
      console.log('Checking answer for question type:', this.currentQuestion.type);
      if (!this.currentQuestion) return;

      const userAnswer = providedAnswer || this.userAnswer;
      console.log(`User answer: ${userAnswer}`);
      console.log(`Correct answer: ${this.currentQuestion.correctAnswer}`);
      console.log(`Question type: ${this.currentQuestion.type}`);

      if (this.selectedMode === 'term-to-definition' || this.selectedMode === 'definition-to-term') {
        const correctAnswer = this.selectedMode === 'term-to-definition' 
          ? this.currentQuestion.definition 
          : this.currentQuestion.term;
        
        try {
          const response = await fetch('/api/check-answer', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userAnswer: userAnswer,
              correctAnswer: correctAnswer,
              questionType: 'definition',
              mode: this.selectedMode,
            }),
          });
          const result = await response.json();
          this.isCorrect = result.isCorrect;
          this.answerExplanation = result.explanation;

          console.log('Answer is correct:', this.isCorrect);

          if (this.isCorrect) {
            this.handleCorrectAnswer();
          } else {
            // Добавим обработку неправильного ответа
            this.handleIncorrectAnswer();
          }

          this.playSound(this.isCorrect);
          await this.updateStatistics(this.currentQuestion.event, this.isCorrect);
          
          // Показываем оверлей только для правильных ответов
          if (this.isCorrect) {
            this.showSuccessOverlay = true;
          }

          this.isAnswerChecked = true;
        } catch (error) {
          console.error('Error checking answer:', error);
        }
      } else {
        try {
          const response = await fetch('/api/check-answer', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userAnswer: userAnswer,
              correctAnswer: this.currentQuestion.correctAnswer,
              questionType: this.selectedMode === 'event-description' ? 'event-description' : this.currentQuestion.type,
              eventName: this.currentQuestion.event,
              generatedQuestion: this.$refs.questionDisplay.uniqueQuestion || this.$refs.questionDisplay.questionText
            }),
          });
          const result = await response.json();
          this.isCorrect = result.isCorrect;
          this.answerExplanation = result.explanation;

          console.log('Answer is correct:', this.isCorrect);

          if (this.isCorrect) {
            this.handleCorrectAnswer();
          } else {
            // Добавим обработку неправильного ответа
            this.handleIncorrectAnswer();
          }

          this.playSound(this.isCorrect);
          await this.updateStatistics(this.currentQuestion.event, this.isCorrect);
          
          // Показываем оверлей только для правильных ответов
          if (this.isCorrect) {
            this.showSuccessOverlay = true;
          }

          this.isAnswerChecked = true;
        } catch (error) {
          console.error('Error checking answer:', error);
        }
      }
    },
    async nextQuestion() {
      console.log('Moving to next question');
      this.clearPreviousQuestionData();

      if (this.selectedMode === 'random') {
        this.shuffleQuestions(this.questions);
        this.currentIndex = 0;
        console.log('Questions reshuffled for random mode');
      } else if (this.questions.length > 0) {
        this.currentIndex = (this.currentIndex + 1) % this.questions.length;
        console.log(`New question index: ${this.currentIndex}`);
      }

      this.isCorrect = null;
      this.showSuccessOverlay = false;
      this.isExplanationVisible = false; // Reset explanation visibility

      if (this.selectedMode === 'event-description') {
        await this.generateUniqueQuestion(
          this.currentQuestion.event,
          this.currentQuestion.explanation
        );
      } else if (this.selectedMode === 'image-quiz') {
        this.prepareImageQuizQuestions();
        if (this.$refs.imageQuizComponent) {
          this.$refs.imageQuizComponent.resetSelection();
        }
      } else {
        this.determineDatePart();
      }

      // Reset the ExplanationComponent
      if (this.$refs.explanationComponent) {
        this.$refs.explanationComponent.resetVisibility();
      }

      // Log the current question details
      console.log('Current question:', this.currentQuestion);
      console.log('Question type:', this.currentQuestion.type);
      
      // Log which input component is being used
      let usedComponent = '';
      switch(this.currentQuestion.type) {
        case 'year':
        case 'exact-date':
          usedComponent = 'DateInputComponent';
          break;
        case 'century':
          usedComponent = 'DateInputComponent (century mode)';
          break;
        case 'range':
          usedComponent = 'RangeInputComponent';
          break;
        case 'decade':
          usedComponent = 'DecadeInputComponent';
          break;
        case 'decade-range':
          usedComponent = 'DecadeRangeInputComponent';
          break;
        case 'month-range':
          usedComponent = 'MonthRangeInputComponent';
          break;
        case 'month-year-range':
          usedComponent = 'MonthYearRangeInputComponent';
          break;
        case 'decade-period':
          usedComponent = 'DecadePeriodInputComponent';
          break;
        case 'day-range':
          usedComponent = 'DayRangeInputComponent';
          break;
        case 'month-year':
          usedComponent = 'MonthYearInputComponent';
          break;
        case 'event-description':
        case 'specific-answer':
          usedComponent = 'EventDescriptionInput';
          break;
        case 'term-to-definition':
        case 'definition-to-term':
          usedComponent = 'DefinitionQuestionComponent';
          break;
        case 'image-quiz':
          usedComponent = 'ImageQuizComponent';
          break;
        default:
          usedComponent = 'Unknown';
      }
      console.log('Input component used:', usedComponent);

      console.log('Next question set:', this.currentQuestion);
      console.log('User answer cleared:', this.userAnswer);

      if (this.$refs.questionDisplay) {
        this.$refs.questionDisplay.hint = null;
      }

      this.isAnswerChecked = false;
    },
    determineDatePart,
    shuffleQuestions,
    generateQuestionWording,
    resetExplanationVisibility() {
      this.isExplanationVisible = false;
    },
    playSound(isCorrect) {
      const audio = new Audio(isCorrect ? '/sounds/correct.wav' : '/sounds/incorrect.wav');
      audio.play().catch(error => console.error('Error playing sound:', error));
    },
    handleCorrectAnswer() {
      console.log('App: handleCorrectAnswer called');
      this.showSuccessOverlay = true;
      console.log('App: showSuccessOverlay set to', this.showSuccessOverlay);
    },
    hideSuccessOverlay() {
      console.log('App: hideSuccessOverlay method called');
      this.showSuccessOverlay = false;
      console.log('App: showSuccessOverlay set to', this.showSuccessOverlay);
    },
    checkEventName(userAnswer) {
      console.log('Checking event name:', userAnswer);
      console.log('Current question:', this.currentQuestion);
      
      if (this.currentQuestion && this.currentQuestion.event) {
        this.checkAnswer(userAnswer);
      } else {
        console.error('No current question or event to check against');
      }
    },
    calculateSimilarity() {
      // Implement a simple similarity algorithm (e.g., Levenshtein distance)
      // For now, we'll return a random value between 0 and 1
      return Math.random();
    },
    toggleExplanation() {
      this.isExplanationVisible = !this.isExplanationVisible;
    },
    updateStatistics(questionEvent, isCorrect) {
      return apiUpdateStatistics(this.userId, questionEvent, isCorrect);
    },
    handleEventDescriptionAnswer(answer) {
      this.userAnswer = answer;
      this.checkAnswer();
    },
    async generateUniqueQuestion(event, explanation) {
      try {
        const response = await fetch('/api/generate-question', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ event, explanation }),
        });
        const data = await response.json();
        return data.question;
      } catch (error) {
        console.error('Error generating unique question:', error);
        return `Определите событие по следующему описанию: ${explanation}`;
      }
    },
    clearPreviousQuestionData() {
      this.userAnswer = '';
      this.isCorrect = null;
      this.answerExplanation = '';
      if (this.$refs.questionDisplay) {
        this.$refs.questionDisplay.hint = null;
        this.$refs.questionDisplay.uniqueQuestion = null;
      }
    },
    // Добавим новый метод для обработки неправильных ответов
    handleIncorrectAnswer() {
      console.log('Incorrect answer');
      // Здесь можно добавить дополнительную логику для неправильных ответов
    },
    async loadSequenceQuestions() {
      try {
        const questions = await fetchSequenceQuestions(this.selectedSubject);
        this.sequenceEvents = questions.map((q, index) => ({
          id: index,
          event: q.event,
          year: new Date(q.correctAnswer).getFullYear()
        }));
        console.log('Loaded sequence events:', this.sequenceEvents);
      } catch (error) {
        console.error('Error loading sequence questions:', error);
      }
    },
    async loadNextSequence() {
      await this.loadSequenceQuestions();
    },
    handleSequenceAnswer(isCorrect) {
      this.isCorrect = isCorrect;
      // Дополнительная логика обработки ответа
    },
    handleImageQuizAnswer(isCorrect) {
      this.isCorrect = isCorrect;
      this.playSound(isCorrect);
      this.updateStatistics(this.currentImageQuestion.correctAnswer, isCorrect);
      if (isCorrect) {
        this.showSuccessOverlay = true;
      }
    },
  },
  watch: {
    userAnswer(newValue) {
      console.log('userAnswer changed:', newValue);
    },
    showSuccessOverlay(newValue) {
      console.log('App: showSuccessOverlay changed to', newValue);
    }
  },
  setup() {
    const statistics = ref({});
    const userId = ref('');
    const subjects = ref([]);

    function getUserId() {
      let id = localStorage.getItem('userId');
      if (!id) {
        id = 'user_' + Math.random().toString(36).substr(2, 9);
        localStorage.setItem('userId', id);
      }
      return id;
    }

    async function fetchSubjects() {
      try {
        const response = await fetch('/api/subjects');
        if (response.ok) {
          subjects.value = await response.json();
        } else {
          console.error('Failed to fetch subjects');
        }
      } catch (error) {
        console.error('Error fetching subjects:', error);
      }
    }

    onMounted(() => {
      userId.value = getUserId();
      const savedStatistics = localStorage.getItem(`questionStatistics_${userId.value}`);
      if (savedStatistics) {
        statistics.value = JSON.parse(savedStatistics);
      }
      fetchSubjects();
    });

    watch(statistics, (newStatistics) => {
      localStorage.setItem(`questionStatistics_${userId.value}`, JSON.stringify(newStatistics));
    }, { deep: true });

    return {
      statistics,
      userId,
      subjects,
    };
  },
};
</script>

<style lang="scss">
@import './assets/styles/app.scss';
</style>
<template>
    <div class="multiplayer-game">
      <div v-if="!gameJoined">
        <input v-model="username" placeholder="Введите ваше имя">
        <button @click="createGame">Создать новую игру</button>
        <div>
          <input v-model="gameId" placeholder="ID существующей игры">
          <button @click="joinGame">Присоединиться к игре</button>
        </div>
      </div>
      <div v-else-if="!gameStarted">
        <h3>Ожидание второго игрока...</h3>
        <p>ID вашей игры: {{ gameId }}</p>
        <p>Передайте этот ID другому игроку, чтобы он мог присоединиться.</p>
      </div>
      <div v-else>
        <h2>{{ currentQuestion }}</h2>
        <button 
          v-for="option in options" 
          :key="option" 
          @click="submitAnswer(option)"
        >
          {{ option }}
        </button>
        <div class="scores">
          <p v-for="(score, player) in scores" :key="player">
            {{ player }}: {{ score }}
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import io from 'socket.io-client';
  
  export default {
    data() {
      return {
        socket: null,
        username: '',
        gameId: '',
        gameJoined: false,
        gameStarted: false,
        currentQuestion: '',
        options: [],
        scores: {}
      };
    },
    methods: {
      createGame() {
        if (!this.username) {
          alert('Пожалуйста, введите ваше имя');
          return;
        }
        this.gameId = Math.random().toString(36).substring(2, 8).toUpperCase();
        this.connectToGame();
      },
      joinGame() {
        if (!this.username || !this.gameId) {
          alert('Пожалуйста, введите ваше имя и ID игры');
          return;
        }
        this.connectToGame();
      },
      connectToGame() {
        this.socket = io('http://localhost:5001');  // Обновленный порт
        this.socket.emit('join_game', { username: this.username, game_id: this.gameId });
        
        this.socket.on('game_joined', () => {
          this.gameJoined = true;
        });

        this.socket.on('start_game', () => {
          this.gameStarted = true;
        });
        
        this.socket.on('new_question', (data) => {
          this.currentQuestion = data.question;
          this.options = data.options;
        });
        
        this.socket.on('answer_result', (data) => {
          this.scores = data.scores;
        });
      },
      submitAnswer(answer) {
        this.socket.emit('answer', { username: this.username, game_id: this.gameId, answer });
      }
    }
  };
  </script>
<template>
  <div class="explanation" v-if="shouldShowExplanation">
    <button @click="toggleExplanation" class="toggle-button">
      {{ isExplanationVisible ? 'Скрыть разъяснение' : 'Показать разъяснение' }}
    </button>
    <div v-if="isExplanationVisible && explanation" class="explanation-content">
      <h3>Разъяснение:</h3>
      <p>{{ explanation }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['explanation', 'isVisible', 'questionType', 'selectedMode'],
  data() {
    return {
      isExplanationVisible: false
    }
  },
  computed: {
    shouldShowExplanation() {
      return this.selectedMode !== 'event-description' && this.explanation
    }
  },
  methods: {
    toggleExplanation() {
      this.isExplanationVisible = !this.isExplanationVisible
    },
    resetVisibility() {
      this.isExplanationVisible = false;
    }
  },
  watch: {
    isVisible(newValue) {
      this.isExplanationVisible = newValue
    }
  }
}
</script>

<template>
    <div>
      <input 
        type="number" 
        v-model="localStartDecade" 
        placeholder="Начальное десятилетие (например, 1940)"
        @input="updateUserAnswer"
      />
      <input 
        type="number" 
        v-model="localEndDecade" 
        placeholder="Конечное десятилетие (например, 1980)"
        @input="updateUserAnswer"
      />
      <span>{{ era }}</span>
    </div>
  </template>
  
  <script>
  export default {
    props: ['userAnswer', 'era'],
    data() {
      return {
        localStartDecade: '',
        localEndDecade: ''
      }
    },
    methods: {
      updateUserAnswer() {
        if (this.localStartDecade && this.localEndDecade) {
          const newValue = `${this.localStartDecade}-${this.localEndDecade}`;
          this.$emit('update:userAnswer', newValue);
          console.log('Emitting new userAnswer:', newValue);
        }
      },
      updateLocalValues() {
        if (this.userAnswer) {
          [this.localStartDecade, this.localEndDecade] = this.userAnswer.split('-');
        } else {
          this.localStartDecade = '';
          this.localEndDecade = '';
        }
      }
    },
    watch: {
      userAnswer: {
        immediate: true,
        handler() {
          this.updateLocalValues();
        }
      }
    },
    mounted() {
      this.updateLocalValues();
    }
  }
  </script>
<template>
    <div>
      <select v-model="localAnswer" @change="updateUserAnswer">
        <option value="">Выберите период</option>
        <option value="начало">Начало</option>
        <option value="середина">Середина</option>
        <option value="конец">Конец</option>
      </select>
      <span>{{ era }}</span>
    </div>
  </template>
  
  <script>
  export default {
    props: ['userAnswer', 'era'],
    data() {
      return {
        localAnswer: ''
      }
    },
    methods: {
      updateUserAnswer() {
        this.$emit('update:userAnswer', this.localAnswer);
      },
      updateLocalValue() {
        this.localAnswer = this.userAnswer || '';
      }
    },
    watch: {
      userAnswer: {
        immediate: true,
        handler() {
          this.updateLocalValue();
        }
      }
    },
    mounted() {
      this.updateLocalValue();
    }
  }
  </script>
<template>
    <div class="image-quiz">
      <h3>{{ currentQuestion.question }}</h3>
      <img :src="currentQuestion.imageUrl" alt="Quiz Image" class="quiz-image">
      <div class="options">
        <button 
          v-for="option in currentQuestion.options" 
          :key="option"
          @click="checkAnswer(option)"
          :class="{ 'selected': selectedOption === option }"
        >
          {{ option }}
        </button>
      </div>
    </div>
</template>

<script>
export default {
  props: ['currentQuestion'],
  data() {
    return {
      selectedOption: null
    };
  },
  methods: {
    checkAnswer(option) {
      this.selectedOption = option;
      const isCorrect = option === this.currentQuestion.correctAnswer;
      this.$emit('answer-checked', isCorrect);
    },
    resetSelection() {
      this.selectedOption = null;
    }
  },
  watch: {
    currentQuestion() {
      this.resetSelection();
    }
  }
};
</script>

<style scoped>
.image-quiz {
  text-align: center;
}
.quiz-image {
  max-width: 100%;
  height: auto;
  margin: 1rem 0;
}
.options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}
button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}
.selected {
  background-color: #4CAF50;
  color: white;
}
</style>
export function checkAnswer() {
  try {
    console.log('checkAnswer called, question type:', this.currentQuestion?.type);
    console.log('this.currentQuestion:', JSON.stringify(this.currentQuestion));
    console.log('this.userAnswer:', this.userAnswer);

    if (!this.currentQuestion) {
      console.error('No current question');
      return;
    }

    let correct = false;
    const userAnswer = String(this.userAnswer);
    const correctAnswerParts = this.currentQuestion.correctAnswer.split('.');

    if (this.datePart === 'day') {
      console.log('Checking day:', this.userAnswer);
      console.log('Correct day:', this.currentQuestion.correctAnswer);
      const correctDay = correctAnswerParts[0];
      correct = userAnswer.padStart(2, '0') === correctDay;
    } else if (this.datePart === 'month') {
      console.log('Checking month:', this.userAnswer);
      console.log('Correct month:', this.currentQuestion.correctAnswer);
      const correctMonth = correctAnswerParts[1];
      correct = userAnswer.padStart(2, '0') === correctMonth;
    } else if (this.datePart === 'year' || this.currentQuestion.type === 'year') {
      console.log('Checking answer:', this.userAnswer);
      console.log('Correct answer:', this.currentQuestion.correctAnswer);
      console.log('Question type:', this.currentQuestion.type);
      const userYear = Number(this.userAnswer);
      const correctYear = Number(this.currentQuestion.correctAnswer);
      correct = !isNaN(userYear) && userYear === correctYear;
    } else if (this.currentQuestion.type === 'month-year-range') {
      console.log('Entering month-year-range check');
      try {
        console.log('Checking answer:', this.userAnswer);
        console.log('Correct answer:', this.currentQuestion.correctAnswer);
        console.log('Question type:', this.currentQuestion.type);
        
        if (!this.userAnswer || !this.currentQuestion.correctAnswer) {
          console.error('User answer or correct answer is missing');
          return;
        }
        
        const [userStart, userEnd] = this.userAnswer.split('-');
        const [correctStart, correctEnd] = this.currentQuestion.correctAnswer.split('-');
        
        console.log('User start and end:', userStart, userEnd);
        console.log('Correct start and end:', correctStart, correctEnd);
        
        const parseDate = (date) => {
          const [month, year] = date.split('.');
          return { month: parseInt(month, 10), year: parseInt(year, 10) };
        };
        
        const userStartDate = parseDate(userStart);
        const userEndDate = parseDate(userEnd);
        const correctStartDate = parseDate(correctStart);
        const correctEndDate = parseDate(correctEnd);
        
        console.log('User Start Date:', userStartDate);
        console.log('User End Date:', userEndDate);
        console.log('Correct Start Date:', correctStartDate);
        console.log('Correct End Date:', correctEndDate);
        
        correct = userStartDate.year === correctStartDate.year &&
                  userStartDate.month === correctStartDate.month &&
                  userEndDate.year === correctEndDate.year &&
                  userEndDate.month === correctEndDate.month;
        console.log('Is correct:', correct);
        console.log('Comparison results:',
          'Start Year:', userStartDate.year === correctStartDate.year,
          'Start Month:', userStartDate.month === correctStartDate.month,
          'End Year:', userEndDate.year === correctEndDate.year,
          'End Month:', userEndDate.month === correctEndDate.month
        );
      } catch (error) {
        console.error('Error in month-year-range check:', error);
      }
    } else if (this.currentQuestion.type === 'range') {
      console.log('Checking answer:', this.userAnswer);
      console.log('Correct answer:', this.currentQuestion.correctAnswer);
      console.log('Question type:', this.currentQuestion.type);
      const [userStart, userEnd] = this.userAnswer.split('-').map(Number);
      const [correctStart, correctEnd] = this.currentQuestion.correctAnswer.split('-').map(Number);
      correct = !isNaN(userStart) && !isNaN(userEnd) && 
                userStart === correctStart && userEnd === correctEnd;
    } else if (this.currentQuestion.type === 'decade') {
      console.log('Checking answer:', this.userAnswer);
      console.log('Correct answer:', this.currentQuestion.correctAnswer);
      console.log('Question type:', this.currentQuestion.type);
      const userDecade = Number(this.userAnswer);
      const correctDecade = Number(this.currentQuestion.correctAnswer);
      correct = !isNaN(userDecade) && userDecade === correctDecade;
    } else if (this.currentQuestion.type === 'decade-range') {
      console.log('Checking answer:', this.userAnswer);
      console.log('Correct answer:', this.currentQuestion.correctAnswer);
      console.log('Question type:', this.currentQuestion.type);
      const [userStart, userEnd] = this.userAnswer.split('-').map(Number);
      const [correctStart, correctEnd] = this.currentQuestion.correctAnswer.split('-').map(Number);
      correct = !isNaN(userStart) && !isNaN(userEnd) && 
                userStart === correctStart && userEnd === correctEnd;
    } else if (this.currentQuestion.type === 'month-range') {
      console.log('Checking answer:', this.userAnswer);
      console.log('Correct answer:', this.currentQuestion.correctAnswer);
      console.log('Question type:', this.currentQuestion.type);
      const [userMonths, userYear] = this.userAnswer.split('.');
      const [correctMonths, correctYear] = this.currentQuestion.correctAnswer.split('.');
      correct = userMonths === correctMonths && userYear === correctYear;
    } else if (this.currentQuestion.type === 'decade-period') {
      console.log('Checking answer:', this.userAnswer);
      console.log('Correct answer:', this.currentQuestion.correctAnswer);
      console.log('Question type:', this.currentQuestion.type);
      correct = this.userAnswer === this.currentQuestion.correctAnswer;
    } else if (this.currentQuestion.type === 'exact-date') {
      console.log('Entering exact-date check');
      console.log('Checking exact date:', this.userAnswer);
      console.log('Correct exact date:', this.currentQuestion.correctAnswer);
      correct = this.userAnswer === this.currentQuestion.correctAnswer;
      console.log('Is correct:', correct);
    } else if (this.datePart === 'fullDate') {
      console.log('Checking full date:', this.userAnswer);
      console.log('Correct full date:', this.currentQuestion.correctAnswer);
      // Ensure the input has a full date format (yyyy-mm-dd)
      const dateParts = userAnswer.split('-');
      if (dateParts.length === 3 && dateParts[0] && dateParts[1] && dateParts[2]) {
        try {
          const [year, month, day] = new Date(userAnswer).toISOString().split('T')[0].split('-');
          const normalizedUserAnswer = `${day}.${month}.${year}`;
          correct = normalizedUserAnswer === this.currentQuestion.correctAnswer;
        } catch (error) {
          console.error('Error parsing date:', error);
          correct = false;
        }
      } else {
        // If the date is incomplete or invalid, treat the answer as incorrect
        correct = false;
      }
    } else if (this.currentQuestion.type === 'day-range') {
      console.log('Checking day range:', this.userAnswer);
      console.log('Correct day range:', this.currentQuestion.correctAnswer);
      const [userStartDay, userEndDay, userMonth, userYear] = this.userAnswer.split(/[-.]/);
      const [correctStartDay, correctEndDay, correctMonth, correctYear] = this.currentQuestion.correctAnswer.split(/[-.]/);
      correct = userStartDay === correctStartDay && userEndDay === correctEndDay && userMonth === correctMonth && userYear === correctYear;
    } else if (this.currentQuestion.type === 'month-year') {
      console.log('Checking month-year answer');
      console.log('User answer:', this.userAnswer);
      console.log('Correct answer:', this.currentQuestion.correctAnswer);
      
      correct = this.userAnswer === this.currentQuestion.correctAnswer;
      console.log('Is correct:', correct);
    } else if (this.currentQuestion.type === 'century') {
      console.log('Checking century:', this.userAnswer);
      console.log('Correct century:', this.currentQuestion.correctAnswer);
      const userCentury = Number(this.userAnswer);
      const correctCentury = Number(this.currentQuestion.correctAnswer);
      correct = !isNaN(userCentury) && userCentury === correctCentury;
    } else if (this.currentQuestion.type === 'specific-answer') {
      console.log('Checking specific answer');
      console.log('User answer:', this.userAnswer);
      console.log('Correct answer:', this.currentQuestion.correctAnswer);
      
      // Здесь можно реализовать более сложную логику проверки,
      // например, проверку на наличие ключевых слов
      correct = this.userAnswer.toLowerCase().includes(this.currentQuestion.correctAnswer.toLowerCase());
      console.log('Is correct:', correct);
    } else if (this.currentQuestion.type === 'event-description') {
      // ... (существующий код для event-description)
    }
    
    console.log('After switch statement');
    this.isCorrect = correct;
    console.log('isCorrect set to:', this.isCorrect);
    this.playSound(this.isCorrect);

    if (correct) {
      this.isCorrect = true;
      this.updateStatistics(this.currentQuestion.event, true);
      this.handleCorrectAnswer();
    } else {
      this.isCorrect = false;
      this.updateStatistics(this.currentQuestion.event, false);
    }
  } catch (error) {
    console.error('Error in checkAnswer:', error);
  }
}

export function updateStatistics(questionEvent, isCorrect) {
  const userId = this.userId;
  if (!this.statistics[userId]) {
    this.statistics[userId] = {};
  }
  if (!this.statistics[userId][questionEvent]) {
    this.statistics[userId][questionEvent] = { correct: 0, incorrect: 0 };
  }
  if (isCorrect) {
    this.statistics[userId][questionEvent].correct++;
  } else {
    this.statistics[userId][questionEvent].incorrect++;
  }
}

export function nextQuestion() {
  console.log('Entering nextQuestion method');
  if (this.selectedMode === 'random') {
    console.log('Mode: random');
    this.shuffleQuestions(this.questions);
    this.currentIndex = 0;
  } else if (this.questions.length > 0) {
    console.log('Mode: sequential');
    this.currentIndex = (this.currentIndex + 1) % this.questions.length;
  }

  console.log('Current index:', this.currentIndex);
  console.log('Current question:', JSON.stringify(this.questions[this.currentIndex]));

  this.isCorrect = null;
  this.userAnswer = '';
  this.showSuccessOverlay = false;
  this.isAnswerChecked = false;  // Добавьте эту строку
  
  if (this.selectedMode === 'event-description') {
    console.log('Event description mode');
    // Не нужно ничего делать здесь, так как генерация вопроса происходит в QuestionDisplayComponent
  } else {
    console.log('Question type:', this.currentQuestion.type);
    this.determineDatePart();
  }
  
  if (this.$refs.explanationComponent) {
    this.$refs.explanationComponent.resetVisibility();
  }
  
  console.log('Exiting nextQuestion method');
}

export function shuffleQuestions(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export function generateQuestionWording(event, type) {
  switch (type) {
    case 'year':
      return `В каком году произошло событие "${event}"?`;
    case 'range':
      return `В какие годы происходило событие "${event}"?`;
    case 'decade':
      return `В каком десятилетии произошло событие "${event}"?`;
    case 'decade-range':
      return `С какого по какое десятилетие длилось событие "${event}"?`;
    case 'month-range':
      return `С какого месяца по какой месяц какого года произошло событие "${event}"?`;
    case 'month-year-range':
      return `С какого месяца и года по какой месяц и год длилось событие "${event}"?`;
    case 'decade-period':
      return `В какой период десятилетия произошло событие "${event}"?`;
    case 'exact-date':
      return `В какой день, месяц и год произошло событие "${event}"?`;
    case 'day-range':
      return `С какого по какое число какого месяца и года длилось событие "${event}"?`;
    case 'month-year':
      return `В каком месяце и году началось событие "${event}"?`;
    case 'century':
      return `В каком веке произошло событие "${event}"?`;
    case 'century-range':
      return `С какого по какой век длилось событие "${event}"?`;
    case 'event-description':
      return `Определите событие по следующему описанию:`;
    case 'specific-answer':
      return event; // В этом случае event уже содержит вопрос
    default:
      return `Когда произошло событие "${event}"?`;
  }
}
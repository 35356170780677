<template>
  <div class="result-display">
    <p v-if="isCorrect === false && selectedMode !== 'event-description'" class="error-message animate">
      Неверно. Правильный ответ: {{ correctAnswer }}
    </p>
    <p v-if="isCorrect === false && selectedMode === 'event-description'" class="error-message animate">
      {{ correctAnswer }}
    </p>
    <img v-if="shouldShowImage" :src="imageUrl" alt="Answer Image" class="answer-image">
    <p v-if="explanation" class="explanation">
      {{ explanation }}
    </p>
    <SuccessOverlay :show="show" @hide="handleHide" />
  </div>
</template>

<script>
import SuccessOverlay from './SuccessOverlay.vue';

export default {
  components: {
    SuccessOverlay
  },
  props: {
    isCorrect: Boolean,
    correctAnswer: String,
    show: Boolean,
    explanation: String,
    selectedMode: String,
    imageUrl: String,
    showImage: Boolean,
  },
  computed: {
    shouldShowImage() {
      return this.showImage && this.imageUrl;
    }
  },
  emits: ['hide-overlay'],
  methods: {
    handleHide() {
      console.log('ResultDisplayComponent: hide event received from SuccessOverlay');
      this.$emit('hide-overlay');
    }
  },
  watch: {
    show(newValue) {
      console.log('ResultDisplayComponent: show prop changed to', newValue);
    }
  },
  mounted() {
    console.log('ResultDisplayComponent: Component mounted, initial show value:', this.show);
  },
  beforeUnmount() {
    console.log('ResultDisplayComponent: Component unmounting');
  }
};
</script>

<style scoped>
.animate {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.error-message {
  color: red;
  animation-name: shakeX;
}

@keyframes shakeX {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.answer-image {
  max-width: 100%;
  height: auto;
  margin: 1rem 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>

<template>
  <div>
    <input 
      type="number" 
      v-model="localStartYear" 
      placeholder="Начальный год"
      @input="updateUserAnswer"
    />
    <input 
      type="number" 
      v-model="localEndYear" 
      placeholder="Конечный год"
      @input="updateUserAnswer"
    />
    <span>{{ era }}</span>
  </div>
</template>

<script>
export default {
  props: ['userAnswer', 'era'],
  data() {
    return {
      localStartYear: '',
      localEndYear: ''
    }
  },
  methods: {
    updateUserAnswer() {
      if (this.localStartYear && this.localEndYear) {
        const newValue = `${this.localStartYear}-${this.localEndYear}`;
        this.$emit('update:userAnswer', newValue);
      }
    },
    updateLocalValues() {
      if (this.userAnswer) {
        [this.localStartYear, this.localEndYear] = this.userAnswer.split('-');
      } else {
        this.localStartYear = '';
        this.localEndYear = '';
      }
    }
  },
  watch: {
    userAnswer: {
      immediate: true,
      handler() {
        this.updateLocalValues();
      }
    }
  },
  mounted() {
    this.updateLocalValues();
  }
}
</script>
<template>
  <div>
    <input
      :value="userAnswer"
      @input="$emit('update:userAnswer', $event.target.value)"
      @keydown.enter="submitAnswer"
      :placeholder="placeholderText"
    />
    <button @click="submitAnswer">Проверить Ответ</button>
  </div>
</template>

<script>
export default {
  props: ['description', 'correctEventName', 'userAnswer', 'questionType'],
  emits: ['update:userAnswer', 'submit-answer'],
  computed: {
    placeholderText() {
      return this.questionType === 'specific-answer' ? 'Введите ответ' : 'Введите название события';
    }
  },
  methods: {
    submitAnswer() {
      console.log('Submitting answer:', this.userAnswer);
      console.log('Correct answer:', this.correctEventName);
      this.$emit('submit-answer', this.userAnswer);
    },
  },
};
</script>
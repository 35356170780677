<template>
    <div class="sequence-question">
      <h3>Расположите события в правильном хронологическом порядке:</h3>
      <TransitionGroup name="list" tag="div">
        <div
          v-for="event in localEvents"
          :key="event.id"
          class="event-item"
          draggable="true"
          @dragstart="dragStart($event, event)"
          @dragover.prevent
          @dragenter.prevent
          @drop="drop($event, event)"
        >
          {{ event.event }}
        </div>
      </TransitionGroup>
      <button @click="checkOrder">Проверить порядок</button>
      <button @click="requestNextSequence">Следующая последовательность</button>
      <p v-if="isCorrect !== null">
        {{ isCorrect ? "Правильно!" : "Неправильно. Попробуйте еще раз." }}
      </p>
    </div>
  </template>
  
  <script>
  export default {
    props: ['events'],
    data() {
      return {
        localEvents: [],
        isCorrect: null,
        draggedEvent: null
      }
    },
    mounted() {
      this.localEvents = [...this.events].sort(() => Math.random() - 0.5);
    },
    methods: {
      checkOrder() {
        const correctOrder = this.events.map(e => e.id);
        const currentOrder = this.localEvents.map(e => e.id);
        this.isCorrect = JSON.stringify(correctOrder) === JSON.stringify(currentOrder);
        this.$emit('answer-checked', this.isCorrect);
      },
      dragStart(event, item) {
        this.draggedEvent = item;
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.setData('text/plain', item.id);
        event.target.style.opacity = '0.5';
      },
      drop(event, item) {
        event.target.style.opacity = '1';
        const index = this.localEvents.indexOf(item);
        const draggedIndex = this.localEvents.indexOf(this.draggedEvent);
        this.localEvents.splice(draggedIndex, 1);
        this.localEvents.splice(index, 0, this.draggedEvent);
        this.draggedEvent = null;
      },
      requestNextSequence() {
        this.$emit('next-sequence');
      }
    }
  }
  </script>
  
  <style scoped>
  .event-item {
    padding: 10px;
    margin: 5px 0;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    cursor: move;
  }
  .list-move {
    transition: transform 0.5s;
  }
  </style>
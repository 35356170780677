<template>
    <div>
      <select v-model="localStartMonth" @change="updateUserAnswer">
        <option value="">Начальный месяц</option>
        <option v-for="(month, index) in months" :key="index" :value="String(index + 1).padStart(2, '0')">
          {{ month }}
        </option>
      </select>
      <select v-model="localEndMonth" @change="updateUserAnswer">
        <option value="">Конечный месяц</option>
        <option v-for="(month, index) in months" :key="index" :value="String(index + 1).padStart(2, '0')">
          {{ month }}
        </option>
      </select>
      <input 
        type="number" 
        v-model="localYear" 
        placeholder="Год"
        @input="updateUserAnswer"
      />
      <span>{{ era }}</span>
    </div>
  </template>
  
  <script>
  export default {
    props: ['userAnswer', 'era'],
    data() {
      return {
        localStartMonth: '',
        localEndMonth: '',
        localYear: '',
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
      }
    },
    methods: {
      updateUserAnswer() {
        if (this.localStartMonth && this.localEndMonth && this.localYear) {
          const newValue = `${this.localStartMonth}-${this.localEndMonth}.${this.localYear}`;
          this.$emit('update:userAnswer', newValue);
          console.log('Emitting new userAnswer:', newValue);
        }
      },
      updateLocalValues() {
        if (this.userAnswer) {
          const [months, year] = this.userAnswer.split('.');
          [this.localStartMonth, this.localEndMonth] = months.split('-');
          this.localYear = year;
        } else {
          this.localStartMonth = '';
          this.localEndMonth = '';
          this.localYear = '';
        }
      }
    },
    watch: {
      userAnswer: {
        immediate: true,
        handler() {
          this.updateLocalValues();
        }
      }
    },
    mounted() {
      this.updateLocalValues();
    }
  }
  </script>
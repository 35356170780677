<template>
    <div>
      <select v-model="localStartMonth" @change="updateUserAnswer">
        <option value="">Начальный месяц</option>
        <option v-for="(month, index) in months" :key="index" :value="String(index + 1).padStart(2, '0')">
          {{ month }}
        </option>
      </select>
      <input 
        type="number" 
        v-model="localStartYear" 
        placeholder="Начальный год"
        @input="updateUserAnswer"
      />
      <select v-model="localEndMonth" @change="updateUserAnswer">
        <option value="">Конечный месяц</option>
        <option v-for="(month, index) in months" :key="index" :value="String(index + 1).padStart(2, '0')">
          {{ month }}
        </option>
      </select>
      <input 
        type="number" 
        v-model="localEndYear" 
        placeholder="Конечный год"
        @input="updateUserAnswer"
      />
      <span>{{ era }}</span>
    </div>
  </template>
  
  <script>
  export default {
    props: ['userAnswer', 'era'],
    data() {
      return {
        localStartMonth: '',
        localStartYear: '',
        localEndMonth: '',
        localEndYear: '',
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
      }
    },
    methods: {
      updateUserAnswer() {
        if (this.localStartMonth && this.localStartYear && this.localEndMonth && this.localEndYear) {
          const formatDate = (month, year) => `${month.padStart(2, '0')}.${year}`;
          const newValue = `${formatDate(this.localStartMonth, this.localStartYear)}-${formatDate(this.localEndMonth, this.localEndYear)}`;
          this.$emit('update:userAnswer', newValue);
          console.log('Emitting new userAnswer:', newValue);
        }
      },
      updateLocalValues() {
        if (this.userAnswer) {
          const [start, end] = this.userAnswer.split('-');
          [this.localStartMonth, this.localStartYear] = start.split('.');
          [this.localEndMonth, this.localEndYear] = end.split('.');
        } else {
          this.localStartMonth = '';
          this.localStartYear = '';
          this.localEndMonth = '';
          this.localEndYear = '';
        }
      }
    },
    watch: {
      userAnswer: {
        immediate: true,
        handler() {
          this.updateLocalValues();
        }
      }
    },
    mounted() {
      this.updateLocalValues();
    }
  }
  </script>
export function determineDatePart() {
  if (!this.currentQuestion) {
    console.error('No current question to determine date part.');
    return;
  }

  // Добавьте эти проверки в начало функции
  if (this.currentQuestion.type === 'term-to-definition' || this.currentQuestion.type === 'definition-to-term') {
    this.datePart = 'definition';
    this.datePartLabel = 'Определение';
    return;
  }

  if (this.currentQuestion.type === 'event-description') {
    this.datePart = 'event-description';
    this.datePartLabel = 'Описание события';
    return;
  }

  if (this.currentQuestion.type === 'month-year-range') {
    this.datePart = 'month-year-range';
    this.datePartLabel = 'Период';
    return;
  }

  if (this.currentQuestion.type === 'range') {
    this.datePart = 'range';
    this.datePartLabel = 'Годы';
    return;
  }

  if (this.currentQuestion.type === 'decade') {
    this.datePart = 'decade';
    this.datePartLabel = 'Десятилетие';
    return;
  }

  if (this.currentQuestion.type === 'year') {
    this.datePart = 'year';
    this.datePartLabel = 'Год';
    return;
  }

  if (this.currentQuestion.type === 'decade-range') {
    this.datePart = 'decade-range';
    this.datePartLabel = 'Десятилетия';
    return;
  }

  if (this.currentQuestion.type === 'month-range') {
    this.datePart = 'month-range';
    this.datePartLabel = 'Месяцы';
    return;
  }

  if (this.currentQuestion.type === 'decade-period') {
    this.datePart = 'decade-period';
    this.datePartLabel = 'Период десятилетия';
    return;
  }

  if (this.currentQuestion.type === 'exact-date') {
    this.datePart = 'exact-date';
    this.datePartLabel = 'Точная дата';
    return;
  }

  if (this.currentQuestion.type === 'day-range') {
    this.datePart = 'day-range';
    this.datePartLabel = 'Диапазон дней';
    return;
  }

  if (this.currentQuestion.type === 'month-year') {
    this.datePart = 'month-year';
    this.datePartLabel = 'Месяц и год';
    return;
  }

  if (this.currentQuestion.type === 'century') {
    this.datePart = 'century';
    this.datePartLabel = 'Век';
    return;
  }

  // Перед использованием split, добавьте проверку
  if (this.currentQuestion.correctAnswer) {
    const correctAnswerParts = this.currentQuestion.correctAnswer.split('.');
    
    if (correctAnswerParts[0] === '00' && correctAnswerParts[1] === '00') {
      this.datePart = 'year';
      this.datePartLabel = 'Год';
    } else if (correctAnswerParts[0] === '00') {
      this.datePart = 'month';
      this.datePartLabel = 'Месяц';
    } else {
      this.datePart = 'fullDate';
      this.datePartLabel = 'Дата';
    }
  } else {
    console.error('No correct answer available for the current question.');
    this.datePart = 'unknown';
    this.datePartLabel = 'Неизвестно';
  }
}

<template>
  <div v-if="!questionsLoaded" class="question-mode-selector">
    <h2>Выберите режим игры:</h2>
    <div class="button-grid">
      <button @click="selectModeAsync('random')" class="mode-button">
        <i class="fas fa-random"></i>
        Случайные вопросы по случайной теме
      </button>
      <button @click="selectModeAsync('subject')" class="mode-button">
        <i class="fas fa-book"></i>
        По теме
      </button>
      <button @click="selectModeAsync('event-description')" class="mode-button">
        <i class="fas fa-align-left"></i>
        Определить событие по описанию
      </button>
      <button @click="selectModeAsync('sequence')" class="mode-button">
        <i class="fas fa-sort-numeric-down"></i>
        Последовательность событий
      </button>
      <button @click="selectModeAsync('term-to-definition')" class="mode-button">
        <i class="fas fa-book-open"></i>
        Дать определение термину
      </button>
      <button @click="selectModeAsync('definition-to-term')" class="mode-button">
        <i class="fas fa-lightbulb"></i>
        Определить термин по описанию
      </button>
      <button @click="selectModeAsync('image-quiz')" class="mode-button">
        <i class="fas fa-image"></i>
        Викторина с картинками
      </button>
      <button @click="selectModeAsync('multiplayer')" class="mode-button">
        <i class="fas fa-users"></i>
        Мультиплеер
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['selectMode', 'questionsLoaded'],
  methods: {
    async selectModeAsync(mode) {
      await this.selectMode(mode);
    }
  }
};
</script>

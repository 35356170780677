<template>
    <div>
      <input 
        type="number" 
        v-model="localStartDay" 
        placeholder="Начальный день"
        @input="updateUserAnswer"
      />
      <input 
        type="number" 
        v-model="localEndDay" 
        placeholder="Конечный день"
        @input="updateUserAnswer"
      />
      <select v-model="localMonth" @change="updateUserAnswer">
        <option value="">Месяц</option>
        <option v-for="(month, index) in months" :key="index" :value="String(index + 1).padStart(2, '0')">
          {{ month }}
        </option>
      </select>
      <input 
        type="number" 
        v-model="localYear" 
        placeholder="Год"
        @input="updateUserAnswer"
      />
      <span>{{ era }}</span>
    </div>
  </template>
  
  <script>
  export default {
    props: ['userAnswer', 'era'],
    data() {
      return {
        localStartDay: '',
        localEndDay: '',
        localMonth: '',
        localYear: '',
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
      }
    },
    methods: {
      updateUserAnswer() {
        // Проверяем, что все поля заполнены перед обновлением
        if (this.localStartDay && this.localEndDay && this.localMonth && this.localYear) {
          const newValue = `${this.localStartDay}-${this.localEndDay}.${this.localMonth}.${this.localYear}`;
          this.$emit('update:userAnswer', newValue);
          console.log('Emitting new userAnswer:', newValue);
        }
      },
      updateLocalValues() {
        if (this.userAnswer) {
          const [dayRange, monthYear] = this.userAnswer.split('.');
          const [startDay, endDay] = dayRange.split('-');
          const [month, year] = monthYear ? monthYear.split('.') : ['', ''];
          
          // Обновляем только пустые поля
          this.localStartDay = this.localStartDay || startDay;
          this.localEndDay = this.localEndDay || endDay;
          this.localMonth = this.localMonth || month;
          this.localYear = this.localYear || year;
        }
      }
    },
    watch: {
      userAnswer: {
        immediate: true,
        handler(newValue, oldValue) {
          // Обновляем локальные значения только если userAnswer изменился извне
          if (newValue !== oldValue) {
            this.updateLocalValues();
          }
        }
      }
    },
    mounted() {
      this.updateLocalValues();
    }
  }
  </script>
<template>
    <transition name="fade">
      <div v-if="show" class="success-overlay">
        <div class="success-content">
          <h1>Верно!</h1>
          <p>Отличная работа!</p>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    props: {
      show: Boolean
    },
    data() {
      return {
        timeoutId: null
      };
    },
    watch: {
      show(newValue) {
        console.log('SuccessOverlay: show prop changed to', newValue);
        if (newValue) {
          this.setHideTimeout();
        } else {
          this.clearHideTimeout();
        }
      }
    },
    methods: {
      setHideTimeout() {
        console.log('SuccessOverlay: Setting timeout to hide overlay');
        this.clearHideTimeout();
        this.timeoutId = setTimeout(() => {
          console.log('SuccessOverlay: Timeout triggered, emitting hide event');
          this.$emit('hide');
        }, 1000);
      },
      clearHideTimeout() {
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
          this.timeoutId = null;
        }
      }
    },
    mounted() {
      console.log('SuccessOverlay: Component mounted, initial show value:', this.show);
      if (this.show) {
        this.setHideTimeout();
      }
    },
    beforeUnmount() {
      console.log('SuccessOverlay: Component unmounting');
      this.clearHideTimeout();
    }
  }
  </script>
  
  <style scoped>
  .success-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 128, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .success-content {
    text-align: center;
    color: white;
    font-size: 2em;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
<template>
    <div class="statistics">
      <h3>Статистика</h3>
      <ul v-if="userStatistics">
        <li v-for="(stat, event) in userStatistics" :key="event">
          {{ event }}: Правильно - {{ stat.correct }}, Неправильно - {{ stat.incorrect }}
        </li>
      </ul>
      <p v-else>Нет доступной статистики</p>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      statistics: {
        type: Object,
        required: true
      },
      userId: {
        type: String,
        required: true
      }
    },
    computed: {
      userStatistics() {
        return this.statistics[this.userId] || null;
      }
    }
  };
  </script>
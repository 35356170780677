<template>
    <div class="definition-question">
      <h3>{{ questionText }}</h3>
      <input v-model="userAnswer" @keyup.enter="submitAnswer" :placeholder="inputPlaceholder" />
      <button @click="submitAnswer">Проверить</button>
    </div>
  </template>
  
  <script>
  export default {
    props: ['currentQuestion', 'selectedMode'],
    data() {
      return {
        userAnswer: '',
      };
    },
    computed: {
      questionText() {
        if (this.selectedMode === 'term-to-definition') {
          return `Что означает термин "${this.currentQuestion.term}"?`;
        } else {
          return `Дайте определение для: ${this.currentQuestion.definition}`;
        }
      },
      inputPlaceholder() {
        return this.selectedMode === 'term-to-definition' ? 'Введите определение' : 'Введите термин';
      },
    },
    methods: {
      submitAnswer() {
        this.$emit('answer-submitted', this.userAnswer);
        this.userAnswer = '';
      },
    },
};
</script>
<template>
    <div v-if="sources && sources.length > 0" class="sources">
      <h3>Источники:</h3>
      <ul>
        <li v-for="(source, index) in sources" :key="index">
          <a :href="source.url" target="_blank" rel="noopener noreferrer">{{ source.description || source.url }}</a>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      sources: {
        type: Array,
        default: () => []
      }
    }
  }
  </script>
  
  <style scoped>
  .sources {
    margin-top: 1rem;
  }
  .sources ul {
    list-style-type: none;
    padding-left: 0;
  }
  .sources li {
    margin-bottom: 0.5rem;
  }
  </style>
<template>
  <div>
    <!-- Input for day -->
    <input 
      v-if="datePart === 'day'" 
      type="number" 
      :value="userAnswer" 
      @input="updateUserAnswer($event.target.value)"
      placeholder="Введите день"
    />

    <!-- Select for month -->
    <select 
      v-if="datePart === 'month'" 
      :value="userAnswer" 
      @change="updateUserAnswer($event.target.value)"
    >
      <option disabled value="">Выберите месяц</option>
      <option value="01">Январь</option>
      <option value="02">Февраль</option>
      <option value="03">Март</option>
      <option value="04">Апрель</option>
      <option value="05">Май</option>
      <option value="06">Июнь</option>
      <option value="07">Июль</option>
      <option value="08">Август</option>
      <option value="09">Сентябрь</option>
      <option value="10">Октябрь</option>
      <option value="11">Ноябрь</option>
      <option value="12">Декабрь</option>
    </select>

    <!-- Input for year -->
    <input 
      v-if="datePart === 'year'" 
      type="number" 
      :value="userAnswer" 
      @input="updateUserAnswer($event.target.value)"
      placeholder="Введите год"
    />

    <!-- Input for full date or exact date -->
    <input 
      v-if="datePart === 'fullDate' || datePart === 'exact-date'" 
      type="date" 
      :value="formatDateForInput(userAnswer)"
      @input="updateUserAnswer($event.target.value)"
    />

    <!-- Input for century -->
    <input 
      v-if="datePart === 'century'" 
      type="number" 
      :value="userAnswer" 
      @input="updateUserAnswer($event.target.value)"
      placeholder="Введите век"
    />
  </div>

  <span>{{ era }}</span>
</template>

<script>
export default {
  props: {
    datePart: String,
    userAnswer: String,
    era: String,
  },
  methods: {
    updateUserAnswer(value) {
      if (this.datePart === 'exact-date' || this.datePart === 'fullDate') {
        const [year, month, day] = value.split('-');
        value = `${day}.${month}.${year}`;
      }
      this.$emit('update:userAnswer', value);
    },
    formatDateForInput(date) {
      if (!date) return '';
      const [day, month, year] = date.split('.');
      return `${year}-${month}-${day}`;
    }
  }
};
</script>
